<script setup>
	import { defineProps } from 'vue';
	const props = defineProps({
		bookingInfo: Object
	})
</script>
<template>
	<div class="c-booking-panel-offer">
		<div class="c-booking-panel-offer-header">
			<h3 class="c-booking-panel-offer-heading left-col">Offers and deals</h3>
			<p class="c-booking-panel-offer-heading right-col" v-if="bookingInfo.nights > 0">Price for {{bookingInfo.nights}} nights</p>
			<p class="c-booking-panel-offer-heading right-col" v-else style="color: #D5474C">Please select one night minimum</p>
		</div>
		<div class="c-booking-panel-offers">
			<div v-if="!bookingInfo.isOffersLoaded">
				<div class="loader">
					<span></span>
				</div>
			</div>
			<div v-else>
				<div v-if="bookingInfo.offers !== null || bookingInfo.messages?.length > 0">
					<div v-if="bookingInfo.offers !== null">
						<div v-for="(offer, index) in bookingInfo.offers" class="c-booking-panel-offer__item-wrapper">
							<div class="c-booking-panel-offer__item">
								<div class="c-booking-panel-offer__item-info-left">
									<p>{{offer.name}} <span class="c-modal-trigger js-modal__trigger" :aria-controls='"offerid-"+index+offer.allotmentId'><i class="iconf-warning"></i></span></p>
								</div>
								<div class="c-booking-panel-offer__item-info-right">
									<div v-if="!offer.isAvailable" class="error">
										<p>Sorry, unavailable, please adjust your dates or guests.</p>
									</div>
									<div v-else>
										<p class="c-booking-panel-offer__item-price">{{ toNZD( offer.totalAmount ) }}</p>
										<p v-if="offer.yourDiscountAmount > 0" class="c-booking-panel-offer__item-deal">Discount Applied {{ toNZD(offer.yourDiscountAmount) }}</p>
										<p v-if="offer.potentialDiscountAmount > 0" class="c-booking-panel-offer__item-deal"> Members save {{ toNZD(offer.potentialDiscountAmount) }}</p>
									</div>

								</div>
								<div class="c-booking-panel-offer__item-info-left">
									<div v-if="offer.urgencyText">
										<p class="c-booking-panel-offer__item-strapline">{{offer.urgencyText}}</p>
									</div>

								</div>
								<div class="c-booking-panel-offer__item-info-right">

									<div v-if="offer.isAvailable">
										<addtocart :index="index"
												   :item="{
												memberEmail: offer.memberEmail,
												additionalBookingDetail: offer.additionalBookingDetail,
												allotmentId: offer.allotmentId,
												bookingDetail: offer.bookingDetail,
												conditions: offer.conditions,
												dailyBreakdown: offer.dailyBreakdown,
												dimensionsRequired: offer.dimensionsRequired,
												conditions: offer.conditions,
												chargeableExtras: offer.chargeableExtras,
												hotelId: offer.hotelId,
												infoPanel: offer.infoPanel,
												image: bookingInfo.image,
												isAvailable: offer.isAvailable,
												name: offer.name,
												promoCode: offer.promoCode,
												roomTypeId: offer.roomTypeId,
												totalAmount: offer.totalAmount,
												yourDiscountAmount: offer.yourDiscountAmount,
												potentialDiscountAmount: offer.potentialDiscountAmount,
												urgencyText: offer.urgencyText,
												vehicleDetail: offer.vehicleDetail,
												date: {
													start: format_date(bookingInfo.date.start,'YYYY-MM-DD'),
													end: format_date(bookingInfo.date.end,'YYYY-MM-DD')
												},
												guest: {
													adult: bookingInfo.guest.adult,
													children: bookingInfo.guest.children,
													infant: bookingInfo.guest.infant,
												},
											   totalGuest: bookingInfo.totalGuest,
											   nights: bookingInfo.nights,
											   parkName: bookingInfo.parkName,
											   maxAdultOccupancy: bookingInfo.maxAdultOccupancy,
											   maxChildOccupancy: bookingInfo.maxChildOccupancy

											}"></addtocart>
									</div>
								</div>
								<info-modal :index="index" :info-panel="offer.infoPanel" :allotment-id="offer.allotmentId"></info-modal>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="c-booking-panel-offer__item">
							<p class="error" v-for="(message) in bookingInfo.messages">{{message}}</p>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="c-booking-panel-offer__item">
						<p class="error">No results available for selected dates and/or guests.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import infoModal from "../infoModal/infoModal";
	import moment from 'moment';

	export default {
		name: 'offers-panel',
		components: {
			infoModal
		},
		data() {
			return {
				title: "title",
				expanded: false
			}
		},
		methods: {
			clickHandler: function () {
				this.expanded = !this.expanded;
			},

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},
			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},
		}
	};
</script>